<template>
  <Header></Header>
  <el-alert
    title="已绑定手机号的用户，请通过此页面重新找回密码。尚未绑定手机号的用户，请联系授课老师或管理员为您重置密码。"
    type="warning"
    center
    show-icon
  />
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>找回密码</span>
      </div>
    </template>
    <el-form ref="fFormRef" :model="fForm" :rules="fRules" label-width="100px">
      <el-form-item label="手机号" prop="tel">
        <el-input v-model="fForm.tel" placeholder="请输入手机号"></el-input>
      </el-form-item>
      <el-form-item class="warnigBig" label="新密码" prop="newPassword">
        <el-input
          v-model="fForm.newPassword"
          :type="showPassword ? 'text' : 'password'"
          placeholder="8-32位，含大小写字母、数字或特殊符号的其中两项"
        />
        <div class="eye-p">
          <img
            v-show="showPassword"
            src="@/assets/image/eye.png"
            @click="showPassword = false"
          />
          <img
            v-show="!showPassword"
            src="@/assets/image/closeEye.png"
            @click="showPassword = true"
          />
        </div>
      </el-form-item>
      <el-form-item label="确认密码" prop="rePassword">
        <el-input
          v-model="fForm.rePassword"
          :type="showPassword2 ? 'text' : 'password'"
          placeholder="需和上次输入一致"
        />
        <div class="eye-p">
          <img
            v-show="showPassword2"
            src="@/assets/image/eye.png"
            @click="showPassword2 = false"
          />
          <img
            v-show="!showPassword2"
            src="@/assets/image/closeEye.png"
            @click="showPassword2 = true"
          />
        </div>
      </el-form-item>
      <el-form-item label="验证码" prop="sms">
        <el-row :gutter="20">
          <el-col :span="18" :xs="14">
            <el-input
              v-model="fForm.sms"
              placeholder="请输入验证码"
              @blur="verifyCode(fForm.tel, fForm.sms, 'forgetPassword')"
            />
          </el-col>
          <el-col v-show="sendBtnShow" :span="6" :xs="4">
            <el-button
              @click="
                sendVerify(fForm.tel, 'forgetPassword');
                setTime();
              "
              >发送验证码</el-button
            >
          </el-col>
          <el-col v-show="!sendBtnShow" :span="6">
            <el-button>重新发送({{ counter }}s)</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item class="mobileAgreement">
        <el-checkbox
          v-model="customCheck"
          type="checkbox"
          class="custom-control-input"
          id="customCheck"
        />
        <label class="custom-control-label" for="customCheck"
          >我已阅读并同意<router-link target="_blank" to="/agreement"
            >《外研教学评用户协议》</router-link
          >
          <router-link target="_blank" to="/privacy"
            >《外研教学评隐私政策》</router-link
          ></label
        >
      </el-form-item>
      <el-form-item class="mobile-btn">
        <el-button class="largeBtn" type="primary" @click="resetPassword()"
          >确认修改</el-button
        >
      </el-form-item>
    </el-form>
    <div class="clearFloat">
      <div class="floatR">
        <router-link to="/login">登录系统</router-link>
      </div>
    </div>
  </el-card>
</template>

<script>
import Header from "@/components/Header.vue";
import { sendVerify, verifyCode } from "@/utils/utils.js";
import { toRefs, reactive, getCurrentInstance } from "vue";
import { toResetPassword } from "@/api/api.js";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import {
  validatePassword,
  validateTel,
  validateNull,
} from "@/utils/validate.js";
export default {
  components: { Header },
  setup() {
    const { proxy } = getCurrentInstance(); // 使用proxy代替ctx，因为ctx只在开发环境有效
    const router = useRouter();
    const state = reactive({
      fForm: {
        tel: "",
        newPassword: "",
        rePassword: "",
        sms: "",
      },
      fFormRef: null,
      customCheck: false,
      sendBtnShow: true,
      counter: 60,
      showPassword: false,
      showPassword2: false,
    });
    const validateRePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else {
        if (value !== state.fForm.newPassword) {
          callback(new Error("两次密码输入不一致！"));
        }
        callback();
      }
    };
    const fRules = {
      tel: [{ validator: validateTel, trigger: "blur" }],
      sms: [{ validator: validateNull, trigger: "blur" }],
      newPassword: [{ validator: validatePassword, trigger: "blur" }],
      rePassword: [{ validator: validateRePassword, trigger: "blur" }],
    };
    const resetPassword = () => {
      state.fFormRef.validate((valide) => {
        if (valide) {
          if (state.customCheck) {
            toResetPassword({
              newPassword: proxy.$encryption(state.fForm.newPassword),
              mobile: state.fForm.tel,
              code: state.fForm.sms,
            }).then((response) => {
              if (response["status"] == 0) {
                ElMessage({
                  message: "修改密码成功，即将跳转登录页......",
                  type: "success",
                });
                setTimeout(() => router.push("/login"), 3000);
              } else {
                ElMessage({
                  message: response["msg"],
                  type: "warning",
                });
              }
            });
          } else {
            ElMessage({
              message: "请阅读并选中协议～",
              type: "warning",
            });
          }
        }
      });
    };
    const setTime = () => {
      var timer;
      if (state.sendBtnShow) {
        state.sendBtnShow = false;
        timer = setInterval(() => {
          if (state.counter > 0 && state.counter <= 60) {
            state.counter--;
          } else {
            state.sendBtnShow = true;
            state.counter = 60;
            clearInterval(timer);
          }
        }, 1000);
      }
    };
    return {
      ...toRefs(state),
      resetPassword,
      sendVerify,
      verifyCode,
      fRules,
      setTime,
    };
  },
};
</script>

<style lang="scss" scoped></style>
